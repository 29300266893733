import { toast } from 'react-toastify';

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
  type: 'info',
  progress: undefined
};

export const setToast = (config, message) => {
  toast(message, config);
};
// export const roundOff = (num) => {
//   return +(Math.round(num + 'e+2') + 'e-2');
// };

// export const roundOffTwo = (num) => {
//   return +parseFloat(num).toFixed(2);
// };
export const roundOffTwo2 = (num) => {
  return parseFloat(num).toFixed(2);
};

export const handleDownloadInvoice = ({ id, invoiceId }) => {
  const link = document.createElement('a');
  link.href = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
  link.target = '__blank';
  link.setAttribute('download', `invoice_${invoiceId}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  document.body.removeChild(link);
  // const url = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
  // const fileName = `invoice_${invoiceId}.pdf`;

  // fetch(url)
  //   .then((response) => response.blob())
  //   .then((blob) => {
  //     const link = document.createElement('a');
  //     const objectUrl = window.URL.createObjectURL(blob);
  //     link.href = objectUrl;
  //     link.target = '__blank';
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //     window.URL.revokeObjectURL(objectUrl); // Cleanup
  //   })
  //   .catch((error) => console.error('Download failed:', error));
};
